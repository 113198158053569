import RouterPaths from "./router";

import React from "react";

import { Provider } from "react-redux"

import hooks from './hooks'
function App() {
    return (
        <Provider store={hooks} >
            <RouterPaths />

        </Provider>


    );
}

export default App;
