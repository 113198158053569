import axios from "axios";



export const Axios = axios

export const apiSecure = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    /*headers: {
        "api": process.env.REACT_APP_API_KEY
    },*/
});


apiSecure.interceptors.response.use(

    function (response) {

        return response;
    },
    function (error) {

        if (error?.response?.status === 401) {
            window.location.href = '/';
            
        }
        return Promise.reject(error);
    }
);

export default apiSecure; 