import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Pagination = forwardRef(({ count, page, onChange }, ref) => {
    const pages = Array.from({ length: count }, (_, index) => index + 1);

    const handlePageChange = (event, value) => {
        event.preventDefault();
        if (value !== page) {
            onChange(event, value);
        }
    };

    const renderPaginationButtons = () => {
        const groupSize = 5;
        const currentPageGroup = Math.ceil(page / groupSize);
        const startPage = (currentPageGroup - 1) * groupSize + 1;
        const endPage = Math.min(startPage + groupSize - 1, count);

        return (
            <>
                <button
                    className={styles['pagination-button']}
                    disabled={page === 1}
                    onClick={(event) => handlePageChange(event, page - 1)}
                >
                    Prev
                </button>
                {pages.slice(startPage - 1, endPage).map((p) => (
                    <button
                        key={p}
                        className={`${styles['pagination-button']} ${p === page ? styles.active : ''}`}
                        onClick={(event) => handlePageChange(event, p)}
                    >
                        {p}
                    </button>
                ))}
                <button
                    className={styles['pagination-button']}
                    disabled={page === count}
                    onClick={(event) => handlePageChange(event, page + 1)}
                >
                    Next
                </button>
            </>
        );
    };

    return (
        <div className={styles.pagination} ref={ref}>
            {renderPaginationButtons()}
        </div>
    );
});

Pagination.propTypes = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default Pagination;
