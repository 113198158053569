import { createSlice } from "@reduxjs/toolkit";


export const eventosReducer = createSlice({
    name: "eventos",
    initialState: {
        showIndicatorRangeOD: false
    },
    reducers: {
        setBoxSelectedItem: (state, action) => {
            state.showIndicatorRangeOD = action.payload;
        }

    }
});

export const { setBoxSelectedItem} = eventosReducer.actions;
export default eventosReducer.reducer;