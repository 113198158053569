import { useEffect, useState } from 'react';
import { PATH_IMAGENS_BACKGROUND_IMAGEN, PATH_NOIMAGEN_AVAILABLE } from '../../services/utils'
import { deleteBackGroundImagenInfo, getBackGroundImagenInfo, handlerErrorResponse, updateBackGroundImagenInfo, validateFields } from '../functions/process';
import useStyles from "../styles/styles";

export default function ModalEventEdit({ isOpenModal, setIsOpenModal, id, setEnableSearchMain }) {


    const classes = useStyles();

    const [loading, setLoading] = useState(false)


    const [errors, setErrors] = useState({
        successResponse: "",
        errorsResponse: "",
        eventTitle: "",
        eventSubtitle: "",
        eventPath: "",
    })

    const [classFade, setClassFade] = useState('')
    const [styleDisplay, setStateDisplay] = useState('none')

    //MODAL ADD CATEGORIA

    //form
    const [defaultBgImg, setDefaultBgImg] = useState(PATH_NOIMAGEN_AVAILABLE)



    //FORMA DATA
    const [eventTitle, setEventTitle] = useState('')
    const [eventSubtitle, setEventSubtitle] = useState('')
    const [eventPath, setEventPath] = useState('')

    const [errorGlobal, setErrorGlobal] = useState([])
    const [enableSearch, setEnableSearch] = useState(false)


    useEffect(() => {
        setErrors(errorGlobal)
    }, [errorGlobal])

    useEffect(() => {

        if (isOpenModal) {
            setEnableSearch(true)
            setClassFade('show')
            setStateDisplay('block')
            setLoading(false)
            setErrorGlobal([])
        }

    }, [isOpenModal])


    useEffect(() => {

        if (enableSearch) {
            ///GET DATA
            getBackGroundImagenInfo(id).then((resp) => {

                //console.log('consutar', resp)

                if (resp.length) {

                    let main = resp[0]


                    setEventTitle((main.title) ? main.title : "")
                    setEventSubtitle((main.subtitle) ? main.subtitle : "")
                    // console.log(PATH_IMAGENS_BACKGROUND_IMAGEN + "/" + main.id + "/" + main.path)
                    setEventPath(PATH_IMAGENS_BACKGROUND_IMAGEN + "/" + main.id + "/" + main.path)
                    setDefaultBgImg(PATH_IMAGENS_BACKGROUND_IMAGEN + "/" + main.id + "/" + main.path)


                }


            }).catch((err) => {

                if (err?.response?.status === 401) {
                    window.location.href = "/"
                    return false
                }
            })
            setEnableSearch(false)
        }
    }, [enableSearch, id])



    const closeModal = () => {
        setStateDisplay('none')
        setIsOpenModal(false)
        setEnableSearchMain(true)
    }



    const imageFlayerChange = (e) => {

        if (e.target.files && e.target.files.length > 0) {
            setDefaultBgImg(URL.createObjectURL(e.target.files[0]));
            setEventPath(e.target.files[0])
        }

    }


    const setDefaultImageFlayer = () => {

        setDefaultBgImg(PATH_NOIMAGEN_AVAILABLE)
        setEventPath('')

    }


    const handleSubmitEditEvent = (event) => {
        event.preventDefault()


        setLoading(true)


        //OBLIGATORIOS
        let Name = validateFields(eventTitle, "text", true, "Titulo")
        if (!Name.success) {
            setErrorGlobal({ ...errors, eventTitle: Name.mensg })
            setLoading(false)
            return false
        } else {
            setErrorGlobal({ ...errors, eventTitle: "" })
        }



        if (!eventPath) {
            setErrorGlobal({ ...errors, eventPath: "Please select At least 1 imagen" })
            setLoading(false)
            return false
        } else {
            setErrorGlobal({ ...errors, eventPath: "" })

        }
        let datasent = {

            eventTitle: eventTitle,
            eventSubtitle: eventSubtitle,
            eventPath: eventPath,
            image_id: id

        }


        updateBackGroundImagenInfo(datasent).then((resp) => {


            if (resp.data.success) {

                setErrorGlobal({ ...errors, successResponse: resp.data.mens })

            }
            setLoading(false)

        }).catch((err) => {

            let respcat = handlerErrorResponse(err)
            setErrorGlobal({ ...errors, errorsResponse: respcat })
            setLoading(false)
        })


    }
    const deteleImage = (event) => {
        event.preventDefault()


        let text = "¿Estas seguro de eliminar esta imagen?!\nEsta acción eliminara de forma permanente el archivo.";
        if (window.confirm(text) === true) {

            deleteBackGroundImagenInfo(id).then((resp) => {

                if (resp.length && resp[0]) {
                    alert("Registro eliminado con exito ")
                    closeModal()
                }

            })
        }


    }


    return (
        <div>

            <div className={"modal fade " + classFade} style={{ display: styleDisplay }} id="kt_modal_invite_friends" tabIndex="-1" aria-hidden="true" data-backdrop="static" aria-labelledby="staticBackdrop" >

                <div className="modal-dialog mw-650px">

                    <div className="modal-content">

                        <div className="modal-header pb-0 border-0 justify-content-end">

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1" onClick={() => closeModal()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
                            <form className="form w-100 " id="kt_sign_in_form_add" action="#" onSubmit={handleSubmitEditEvent} noValidate>
                                <div className="text-center mb-13">

                                    <h1 className="mb-3">Editar Imagen</h1>


                                    <div className="text-muted fw-bold fs-5">
                                        Los campos marcados con <label className='text-danger'>rojo</label>  son obligatorios
                                    </div>

                                </div>

                                {
                                    (errors.errorsResponse) ? (<>
                                        <div className="alert alert-danger" role="alert">
                                            {errors.errorsResponse}
                                        </div>
                                    </>
                                    ) : null
                                }
                                {
                                    (errors.successResponse) ? (<>
                                        <div className="alert alert-success" role="alert">
                                            {errors.successResponse}
                                        </div>
                                    </>
                                    ) : null
                                }



                                <div className="separator d-flex flex-center mb-8">
                                    <span className="text-uppercase bg-body fs-7 fw-bold text-muted px-3"></span>
                                </div>

                                <div className='row'>
                                    <div className="fv-row mb-2 text-center">
                                        <p>
                                            {
                                                (!loading) ? <>
                                                    <button className="btn btn-sm btn-light btn-success" disabled={loading}>
                                                        <span className="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black">
                                                                </rect></svg>
                                                        </span>Guardar
                                                    </button>

                                                    <button className="btn btn-sm btn-light btn-danger mx-xl-11" disabled={loading} onClick={(e) => deteleImage(e)}>
                                                        <span className="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black">
                                                                </rect></svg>
                                                        </span>Eliminar Imagen
                                                    </button>

                                                </> : null
                                            }
                                            {
                                                (loading) ? <>

                                                    <span className={"indicator-progress d-block"}>Guardando...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                </> : null
                                            }
                                        </p>


                                    </div>


                                    <div className="fv-row mb-2">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="name">Titulo</label>

                                        <input className={"form-control form-control-lg  " + classes.bgInput + ((!eventTitle) ? " is-invalid" : "")}
                                            id="name"
                                            type="text"
                                            name="name"
                                            autoComplete="off"
                                            placeholder='Titulo'
                                            value={eventTitle}
                                            onChange={(e) => setEventTitle(e.target.value)}
                                            required
                                        />
                                        <div className={'invalid-feedback' + ((errors.eventTitle) ? " d-block" : null)}>{errors.eventTitle}</div>


                                    </div>
                                    <div className="fv-row mb-2">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="address">Subtitulo</label>

                                        <input className={"form-control form-control-lg  " + classes.bgInput}
                                            id="address"
                                            type="text"
                                            name="address"
                                            autoComplete="off"
                                            placeholder='Subtitulo'
                                            value={eventSubtitle}
                                            onChange={(e) => setEventSubtitle(e.target.value)}
                                        />

                                    </div>



                                    <div className="fv-row mb-2 text-center ">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="flayer">Imagen 	(1920px x 678px) </label>

                                        <div className={'invalid-feedback mt-2' + ((errors.eventPath) ? " d-block" : null)}>{errors.eventPath}</div>


                                        {
                                            (!eventPath) ? (
                                                <div className="col-lg-12">

                                                    <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: "url(" + defaultBgImg + ")" }}>

                                                        <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: "url(" + defaultBgImg + ")" }}></div>

                                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" title="Change avatar">
                                                            <i className="bi bi-pencil-fill fs-7"></i>

                                                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={imageFlayerChange} />


                                                        </label>

                                                    </div>

                                                    <div className="form-text">Allowed file types: png, jpg, jpeg.</div>

                                                </div>


                                            ) : null

                                        }
                                        {
                                            (eventPath) ? (
                                                <div className="col-lg-12">

                                                    <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: "url(" + defaultBgImg + ")" }}>

                                                        <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: "url(" + defaultBgImg + ")" }}></div>

                                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" title="Change avatar">
                                                            <i className="bi bi-pencil-fill fs-7"></i>

                                                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={imageFlayerChange} />


                                                        </label>



                                                        <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"

                                                            data-kt-image-input-action="remove"
                                                            title="Remove avatar"
                                                            onClick={() => setDefaultImageFlayer()}>

                                                            <i className="bi bi-x fs-2"></i>
                                                        </span>



                                                    </div>


                                                    <div className="form-text">Allowed file types: png, jpg, jpeg.</div>

                                                </div>

                                            ) : null


                                        }

                                    </div>

                                </div>
                            </form>

                        </div>


                    </div>

                </div>

            </div>

        </div>
    )
}
