import React from "react";


const Footer = () => {

    return (
        <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">

            <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-center">

                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-bold me-1">2022©</span>
                    <span type="button" className="text-muted ">FULLRUNNERS</span>
                </div>

            </div>

        </div>
    );


}


export default Footer;
