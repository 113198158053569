import { useEffect, useRef, useState } from 'react';
import { PATH_IMAGENS_EVENTS, PATH_IMAGENS_EVENTS_INSCRIPTION, PATH_IMAGENS_EVENTS_RESULTADOS, PATH_NOIMAGEN_AVAILABLE } from '../../services/utils'
import { deleteEventInformation, getEventInformation, handlerErrorResponse, updateEventInfo, validateFields } from '../functions/process';
import useStyles from "../styles/styles";
import { Switch } from '@material-ui/core';

export default function ModalEventEdit({ isOpenModal, setIsOpenModal, id, setEnableSearchMain }) {

    //MODAL ADD CATEGORIA

    const classes = useStyles();

    const [loading, setLoading] = useState(false)

    const [errors, setErrors] = useState({
        successResponse: "",
        errorsResponse: "",
        eventName: "",
        eventAddress: "",
        eventDate: "",
        eventTime: "",

        eventCategory: "",
        eventFlayer: "",

        eventCategoryName: "",
        eventPriceCategory: "",
        categoryfill: false,
        eventPriceCategoryCreditCard:""
    })

    const [classFade, setClassFade] = useState('')
    const [styleDisplay, setStateDisplay] = useState('none')

    const [contadorline, setContadorline] = useState(0)

    const [tableCatList, setTableCatList] = useState([])



    //form
    const [defaultFlayer, setDefaultFlayer] = useState(PATH_NOIMAGEN_AVAILABLE)
    const [defaultFlayerInscripciones, setDefaultFlayerInscripciones] = useState(PATH_NOIMAGEN_AVAILABLE)
    const [defaultFlayerResultados, setDefaultFlayerResultados] = useState(PATH_NOIMAGEN_AVAILABLE)


    const modalCatRef = useRef();

    //FORMA DATA
    const [eventName, setEventName] = useState('')

    const [eventAddress, setEventAddress] = useState('')
    const [eventDate, setEventDate] = useState('')
    const [eventTime, setEventTime] = useState('')
    const [eventComment, setEventComment] = useState('')
    const [eventFlayer, setEventFlayer] = useState('')
    const [errorGlobal, setErrorGlobal] = useState([])
    const [enableSearch, setEnableSearch] = useState(false)

    const [eventLinkInscripcion, setEventLinkInscripcion] = useState('')
    const [eventLinkResultados, setEventLinkResultados] = useState('')


    const [enableModalCategory, setEnableModalCategory] = useState(false)


    const [status, setStatus] = useState(false)
    const [enablePayment, setEnablePayment] = useState(false)


    ////FLAYER DE INSCRIPCIONES
    const [eventFlayerInscripciones, setEventFlayerInscripciones] = useState('')

    ////FLAYER DE RESULTADOS
    const [eventFlayerResultados, setEventFlayerResultados] = useState('')



    useEffect(() => {
        setErrors(errorGlobal)
    }, [errorGlobal])

    useEffect(() => {

        if (isOpenModal) {
            setEnableSearch(true)
            setClassFade('show')
            setStateDisplay('block')
            setLoading(false)
            setErrorGlobal([])
        }




    }, [isOpenModal])


    useEffect(() => {

        if (enableSearch) {
            ///GET DATA
            getEventInformation(id).then((resp) => {


                if (resp?.category_price?.length) {
                    let categorys = resp?.category_price
                    setTableCatList(categorys)
                    setContadorline(resp?.category_price?.length)
                }
                if (resp?.data) {

                    let main = resp.data

                    setEventName((main.name) ? main.name : "")
                    setEventAddress((main.lugar) ? main.lugar : "")
                    setEventDate((main.event_date) ? main.event_date : "")
                    setEventTime((main.time) ? main.time : "")
                    setEventComment((main.descripcion) ? main.descripcion : "")

                    if (main.event_flayer) {
                        setEventFlayer(PATH_IMAGENS_EVENTS + "/" + main.id + "/" + main.event_flayer)
                        setDefaultFlayer(PATH_IMAGENS_EVENTS + "/" + main.id + "/" + main.event_flayer)
                    }

                    setEventLinkInscripcion((main.link_inscripcion) ? main.link_inscripcion : "")
                    setEventLinkResultados((main.link_resultados) ? main.link_resultados : "")

                    setEnablePayment((main.enable_payment === "ACTIVE") ? true : false)
                    setStatus((main.status === "ACTIVE") ? true : false)

                    if (main.event_inscription_flayer) {
                        setEventFlayerInscripciones(PATH_IMAGENS_EVENTS_INSCRIPTION + "/" + main.id + "/" + main.event_inscription_flayer)
                        setDefaultFlayerInscripciones(PATH_IMAGENS_EVENTS_INSCRIPTION + "/" + main.id + "/" + main.event_inscription_flayer)
                    }
                    if (main.event_resultados_flayer) {
                        setEventFlayerResultados(PATH_IMAGENS_EVENTS_RESULTADOS + "/" + main.id + "/" + main.event_resultados_flayer)
                        setDefaultFlayerResultados(PATH_IMAGENS_EVENTS_RESULTADOS + "/" + main.id + "/" + main.event_resultados_flayer)
                    }
                }



            }).catch((err) => {
                if (err?.response?.status === 401) {
                    window.location.href = "/"
                    return false
                }
            })
            setEnableSearch(false)
        }
    }, [enableSearch, id])



    const closeModal = () => {
        setStateDisplay('none')
        setIsOpenModal(false)
        setEnableSearchMain(true)
    }



    const imageFlayerChange = (e) => {

        if (e.target.files && e.target.files.length > 0) {
            setDefaultFlayer(URL.createObjectURL(e.target.files[0]));
            setEventFlayer(e.target.files[0])
        }

    }
    const imageFlayerInscripcionesChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setDefaultFlayerInscripciones(URL.createObjectURL(e.target.files[0]));
            setEventFlayerInscripciones(e.target.files[0])
        }
    }
    const imageFlayerResultadosChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setDefaultFlayerResultados(URL.createObjectURL(e.target.files[0]));
            setEventFlayerResultados(e.target.files[0])
        }
    }


    const setDefaultImageFlayer = () => {

        setDefaultFlayer(PATH_NOIMAGEN_AVAILABLE)
        setEventFlayer('')

    }

    const setDefaultImageFlayerInscription = () => {

        setDefaultFlayerInscripciones(PATH_NOIMAGEN_AVAILABLE)
        setEventFlayerInscripciones('')
    }
    const setDefaultImageFlayerResultados = () => {

        setDefaultFlayerResultados(PATH_NOIMAGEN_AVAILABLE)
        setEventFlayerResultados('')
    }

    const addNewLineCategory = (eventname, eventprice, eventpricecreditcard) => {
        const addcount = contadorline + 1
        setTableCatList([...tableCatList, { id: addcount, name: eventname, price: eventprice, price_credit_card: eventpricecreditcard }])
        setContadorline(addcount)
    }
    const removeLineCategory = (key) => {

        setTableCatList(tableCatList.filter((item) => item.id !== key))
    }

    const handleSubmitEditEvent = (event) => {
        // event.preventDefault()


        setLoading(true)

        //OBLIGATORIOS
        let Name = validateFields(eventName, "text", true, "Nombre")
        if (!Name.success) {
            setErrorGlobal({ ...errors, eventName: Name.mensg })
            setLoading(false)
            return false
        } else {
            setErrorGlobal({ ...errors, eventName: "" })
        }
        let Address = validateFields(eventAddress, "text", true, "Lugar del Evento")
        if (!Address.success) {
            setErrorGlobal({ ...errors, eventAddress: Address.mensg })
            setLoading(false)
            return false
        } else {
            setErrorGlobal({ ...errors, eventAddress: "" })
        }
        let Date = validateFields(eventDate, "text", true, "Fecha")
        if (!Date.success) {
            setErrorGlobal({ ...errors, eventDate: Date.mensg })
            setLoading(false)
            return false
        } else {
            setErrorGlobal({ ...errors, eventDate: "" })

        }
        let Time = validateFields(eventTime, "text", true, "Hora")
        if (!Time.success) {
            setErrorGlobal({ ...errors, eventTime: Time.mensg })
            setLoading(false)
            return false
        } else {
            setErrorGlobal({ ...errors, eventTime: "" })
        }


        // if (!eventFlayer) {
            // setErrorGlobal({ ...errors, eventFlayer: "Please select At least 1 imagen flayer" })
            // setLoading(false)
            // return false
        // } else {
            setErrorGlobal({ ...errors, eventCategory: "" })
            setErrorGlobal({ ...errors, eventFlayer: "" })

        // }

        if (enablePayment && !tableCatList.length) {
            setErrorGlobal({ ...errors, categoryfill: true })
            setLoading(false)
            return false
        } else {
            setErrorGlobal({ ...errors, categoryfill: false })
        }

        let datasent = {

            eventName: eventName,
            eventAddress: eventAddress,
            eventDate: eventDate,
            eventTime: eventTime,
            eventComment: eventComment,
            eventFlayer: eventFlayer,
            eventLinkInscripcion: eventLinkInscripcion,
            eventLinkResultados: eventLinkResultados,
            enablePayment: (enablePayment) ? "ACTIVE" : "INACTIVE",
            tableCatList: tableCatList,
            eventFlayerInscripciones: eventFlayerInscripciones,
            eventFlayerResultados: eventFlayerResultados,
            status: (status) ? "ACTIVE" : "INACTIVE",
            event_id: id

        }

        updateEventInfo(datasent).then((resp) => {

            //console.log("resp",resp)

            if (resp.data.success) {


                setErrorGlobal({ ...errors, successResponse: resp.data.mens })
                //closeModal()

            }

            setLoading(false)

        }).catch((err) => {
            //console.log("err", err)

            if (err?.response?.status === 401) {
                window.location.href = "/"
                return false
            }
            let respcat = handlerErrorResponse(err)
            setErrorGlobal({ ...errors, errorsResponse: respcat })
            setLoading(false)
        })

    }

    const handleDeleteEvent = () => {

        if (window.confirm('¿Estas seguro de eliminar este evento?, Esta acción eliminara de forma permanente el registo.')) {

            let datasent = {
                event_id: id
            }
            deleteEventInformation(datasent).then((resp) => {
                setErrorGlobal({ ...errors, successResponse: resp.data.mens })
                setLoading(false)
            }).catch((err) => {
                if (err?.response?.status === 401) {
                    window.location.href = "/"
                    return false
                }
                let respcat = handlerErrorResponse(err)
                setErrorGlobal({ ...errors, errorsResponse: respcat })
                setLoading(false)
            })
        }

    }

    const ModalAddLine = ({ addNewLineCategory, setEnableModalCategory }) => {

        const [eventCategoryName, setEventCategoryName] = useState('')
        const [eventPriceCategory, setEventPriceCategory] = useState('0.00')
        const [eventPriceCategoryCreditCard, setEventPriceCategoryCreditCard] = useState('0.00')

        const passDatatoAddLine = () => {


            if (eventCategoryName && eventPriceCategory) {
                addNewLineCategory(eventCategoryName, eventPriceCategory, eventPriceCategoryCreditCard)
                setEnableModalCategory(false)
            }

        }
        return (

            <div>

                <div className={"modal fade show modal-add-lines"} style={{ display: 'block' }} tabIndex="-1" aria-hidden="true" data-backdrop="static" aria-labelledby="staticBackdrop" ref={modalCatRef}>

                    <div className="modal-dialog mw-650px">

                        <div className="modal-content">

                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1" onClick={() => setEnableModalCategory(false)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                                        </svg>
                                    </span>

                                </div>

                            </div>

                            <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">

                                <div className="text-center mb-13">

                                    <h1 className="mb-3">Agregar Categoría</h1>


                                    <div className="text-muted fw-bold fs-5">
                                        Los campos marcados con <label className='text-danger'>rojo</label>  son obligatorios
                                    </div>

                                </div>
                                <div className="fv-row mb-2">

                                    <label className="form-label fs-6 fw-bolder text-dark" htmlFor="category_name">Nombre de la Categoría</label>

                                    <input className={"form-control form-control-lg  " + ((!eventCategoryName) ? " is-invalid" : "")}
                                        id="category_name"
                                        type="text"
                                        name="category_name"
                                        autoComplete="off"
                                        placeholder='Nombre de la Categoría'
                                        value={eventCategoryName}
                                        onChange={(e) => setEventCategoryName(e.target.value)}
                                        required
                                    />


                                    <div className={'invalid-feedback ' + ((errors.eventCategoryName && errors.eventCategoryName === "") ? " d-block" : null)}>{errors.eventCategoryName}</div>


                                </div>


                                <div className="fv-row mb-2">

                                    <label className="form-label fs-6 fw-bolder text-dark" htmlFor="name">Precio Yappy </label>

                                    <input className={"form-control form-control-lg  " + ((eventPriceCategory <= 0) ? " is-invalid" : "")}
                                        id="category_price"
                                        type="number"
                                        name="category_price"
                                        autoComplete="off"
                                        step=".01"
                                        min="0"
                                        placeholder='0.00'
                                        value={eventPriceCategory}
                                        onChange={(e) => setEventPriceCategory(e.target.value)}
                                        required
                                    />

                                    <div className={'invalid-feedback ' + ((errors.eventPriceCategory && errors.eventPriceCategory <= 0) ? " d-block" : null)}>{errors.eventPriceCategory}</div>
                                </div>
                                <div className="fv-row mb-2">

                                    <label className="form-label fs-6 fw-bolder text-dark" htmlFor="name">Precio Tarjeta de Crédito </label>

                                    <input className={"form-control form-control-lg  " + ((eventPriceCategoryCreditCard <= 0) ? " is-invalid" : "")}
                                        id="price_credit_card"
                                        type="number"
                                        name="price_credit_card"
                                        autoComplete="off"
                                        step=".01"
                                        min="0"
                                        placeholder='0.00'
                                        value={eventPriceCategoryCreditCard}
                                        onChange={(e) => setEventPriceCategoryCreditCard(e.target.value)}
                                        required
                                    />

                                    <div className={'invalid-feedback ' + ((errors.eventPriceCategoryCreditCard && errors.eventPriceCategoryCreditCard <= 0) ? " d-block" : null)}>{errors.eventPriceCategoryCreditCard}</div>
                                </div>
                                <div className="fv-row mb-2 col-md-12 d-flex justify-content-center">
                                    <button type='button' className='btn btn-sm btn-block btn-success' onClick={() => passDatatoAddLine()} disabled={(!eventCategoryName || eventPriceCategory <= 0 || eventPriceCategoryCreditCard <= 0)}>Agregar</button>
                                </div>


                            </div>


                        </div>

                    </div>

                </div>


            </div>
        )
    }

    return (
        <div>

            <div className={"modal fade " + classFade} style={{ display: styleDisplay }} id="kt_modal_invite_friends" tabIndex="-1" aria-hidden="true" data-backdrop="static" aria-labelledby="staticBackdrop" >

                <div className="modal-dialog mw-650px">

                    <div className="modal-content">

                        <div className="modal-header pb-0 border-0 justify-content-end">

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1" onClick={() => closeModal()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
                            <form className="form w-100 " id="kt_sign_in_form_add" action="#" noValidate>
                                <div className="text-center mb-13">

                                    <h1 className="mb-3">Editar Evento {eventName}</h1>


                                    <div className="text-muted fw-bold fs-5">
                                        Los campos marcados con <label className='text-danger'>rojo</label>  son obligatorios
                                    </div>

                                </div>

                                {
                                    (errors.errorsResponse) ? (<>
                                        <div className="alert alert-danger" role="alert">
                                            {errors.errorsResponse}
                                        </div>
                                    </>
                                    ) : null
                                }
                                {
                                    (errors.successResponse) ? (<>
                                        <div className="alert alert-success" role="alert">
                                            {errors.successResponse}
                                        </div>
                                    </>
                                    ) : null
                                }



                                <div className="separator d-flex flex-center mb-8">
                                    <span className="text-uppercase bg-body fs-7 fw-bold text-muted px-3"></span>
                                </div>

                                <div className='row'>
                                    <div className="fv-row mb-2 text-center">
                                        <p>
                                            {
                                                (!loading) ? <>
                                                    <button type="button" className="btn btn-sm btn-light btn-success mx-3" disabled={loading} onClick={() => handleSubmitEditEvent()}>
                                                        <span className="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black">
                                                                </rect></svg>
                                                        </span>Guardar
                                                    </button>

                                                    <button type="button" className="btn btn-sm btn-light btn-danger" disabled={loading} onClick={() => handleDeleteEvent()}>
                                                        <span className="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black">
                                                                </rect></svg>
                                                        </span>Eliminar Evento
                                                    </button>
                                                </> : null
                                            }
                                            {
                                                (loading) ? <>

                                                    <span className={"indicator-progress d-block"}>Guardando...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                </> : null
                                            }
                                        </p>


                                    </div>

                                    <div className="fv-row mb-2">
                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="link_inscripcion">Estatus</label>
                                        <div className='row'>
                                            <span direction="row" spacing={1} >
                                                <label>Inactivo</label>
                                                <Switch checked={status} className="check-success" name="status" defaultValue={"INACTIVE"} onChange={() => setStatus(!status)} />
                                                <label>Activado</label>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="fv-row mb-2">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="name">Nombre del Evento</label>

                                        <input className={"form-control form-control-lg  " + classes.bgInput + ((!eventName) ? " is-invalid" : "")}
                                            id="name"
                                            type="text"
                                            name="name"
                                            autoComplete="off"
                                            placeholder='Nombre del Evento'
                                            value={eventName}
                                            onChange={(e) => setEventName(e.target.value)}
                                            required
                                        />
                                        <div className={'invalid-feedback' + ((errors.eventName) ? " d-block" : null)}>{errors.eventName}</div>


                                    </div>
                                    <div className="fv-row mb-2">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="address">Lugar del Evento</label>

                                        <input className={"form-control form-control-lg  " + classes.bgInput + ((!eventAddress) ? " is-invalid" : "")}
                                            id="address"
                                            type="text"
                                            name="address"
                                            autoComplete="off"
                                            placeholder='Lugar del Evento'
                                            value={eventAddress}
                                            onChange={(e) => setEventAddress(e.target.value)}

                                            required
                                        />
                                        <div className={'invalid-feedback' + ((errors.eventAddress) ? " d-block" : null)}>{errors.eventAddress}</div>

                                    </div>


                                    <div className="fv-row mb-2 col-md-6">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="fecha">Fecha</label>

                                        <input className={"form-control form-control-lg " + classes.bgInput + ((!eventDate) ? " is-invalid" : "")}
                                            id="fecha"
                                            type="date"
                                            name="fecha"
                                            autoComplete="off"
                                            placeholder='Fecha'
                                            value={eventDate}
                                            onChange={(e) => setEventDate(e.target.value)}

                                        />
                                        <div className={'invalid-feedback' + ((errors.eventDate) ? " d-block" : null)}>{errors.eventDate}</div>

                                    </div>
                                    <div className="fv-row mb-3 col-md-6">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="hora">Hora</label>

                                        <input className={"form-control form-control-lg " + classes.bgInput + ((!eventTime) ? " is-invalid" : "")}
                                            id="hora"
                                            type="time"
                                            name="hora"
                                            autoComplete="off"
                                            placeholder='Hora'
                                            value={eventTime}
                                            onChange={(e) => setEventTime(e.target.value)}
                                        />
                                        <div className={'invalid-feedback' + ((errors.eventTime) ? " d-block" : null)}>{errors.eventTime}</div>


                                    </div>

                                    <div className="fv-row mb-2 ">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="comments">Descripción </label>

                                        <textarea className={"form-control  mb-8 " + classes.bgInput} rows="3" id="comments" placeholder="Ingrese un comentario al evento" onChange={(e) => setEventComment(e.target.value)} value={eventComment}>{eventComment}</textarea>


                                    </div>

                                    <div className="fv-row mb-2">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="link_inscripcion">Link de inscripción</label>

                                        <input className={"form-control form-control-lg  "}
                                            id="link_inscripcion"
                                            type="text"
                                            name="link_inscripcion"
                                            autoComplete="off"
                                            placeholder='Link de inscripción'
                                            value={eventLinkInscripcion}
                                            onChange={(e) => setEventLinkInscripcion(e.target.value)}

                                        />

                                    </div>
                                    <div className="fv-row mb-2">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="link_resultados">Link de Resultados</label>

                                        <input className={"form-control form-control-lg  "}
                                            id="link_resultados"
                                            type="text"
                                            name="link_resultados"
                                            autoComplete="off"
                                            placeholder='Link de Resultados'
                                            value={eventLinkResultados}
                                            onChange={(e) => setEventLinkResultados(e.target.value)}

                                        />

                                    </div>



                                    <div className="fv-row mb-2">
                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="enable_payment">Habilitar Pagos Online</label>


                                        <div className='row'>
                                            <span direction="row" spacing={1} >
                                                <label>Desactivado</label>
                                                <Switch checked={enablePayment} color="primary" name="enable_payment" defaultValue={enablePayment} onChange={() => setEnablePayment(!enablePayment)} />
                                                <label>Activado</label>
                                            </span>
                                        </div>

                                    </div>


                                    {
                                        (enablePayment) ? (


                                            <div className="fv-row mb-2">
                                                <label className="form-label fs-6 fw-bolder text-dark" htmlFor="category_event">Categorías del Evento</label>

                                                <div className={'p-3 invalid-feedback ' + ((errors.categoryfill) ? " d-block" : null)}>{'Si habilita los pagos online, debe relacionar los precios de las categorias '}</div>

                                                <div className='row'>
                                                    <div className='row col-md-6'>

                                                        <button type='button' className='btn btn-sm btn-info' onClick={() => setEnableModalCategory(true)}>
                                                            Agregar Linea
                                                        </button>
                                                    </div>
                                                    <table className='table table-reponsive'>
                                                        <thead >
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">Nombre</th>
                                                                <th scope="col">Yappy</th>
                                                                <th scope="col">Tarjeta de Crédito</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>


                                                        <tbody>

                                                            {tableCatList.length ? (

                                                                tableCatList.map((element) =>
                                                                    <tr key={element.id}>
                                                                        <td>{element.id}</td>
                                                                        <td>{element.name}</td>
                                                                        <td>{element.price}</td>
                                                                        <td>{element.price_credit_card}</td>
                                                                        <td> <i className='fa fa-trash cursor-pointer' onClick={() => removeLineCategory(element.id)}></i></td>
                                                                    </tr>
                                                                )


                                                            ) : (null)}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                        ) : null
                                    }


                                    <div className="fv-row mb-2 text-center">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="flayer">Flayer de Evento </label>

                                        <div className={'invalid-feedback mt-2' + ((errors.eventFlayer) ? " d-block" : null)}>{errors.eventFlayer}</div>


                                        {
                                            (!eventFlayer) ? (
                                                <div className="col-lg-12">

                                                    <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: "url(" + defaultFlayer + ")" }}>

                                                        <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: "url(" + defaultFlayer + ")" }}></div>

                                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" title="Change avatar">
                                                            <i className="bi bi-pencil-fill fs-7"></i>

                                                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={imageFlayerChange} />


                                                        </label>

                                                    </div>

                                                    <div className="form-text">Allowed file types: png, jpg, jpeg.</div>

                                                </div>


                                            ) : null


                                        }
                                        {
                                            (eventFlayer) ? (
                                                <div className="col-lg-12">

                                                    <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: "url(" + defaultFlayer + ")" }}>

                                                        <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: "url(" + defaultFlayer + ")" }}></div>

                                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" title="Change avatar">
                                                            <i className="bi bi-pencil-fill fs-7"></i>

                                                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={imageFlayerChange} />


                                                        </label>



                                                        <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"

                                                            data-kt-image-input-action="remove"
                                                            title="Remove avatar"
                                                            onClick={() => setDefaultImageFlayer()}>

                                                            <i className="bi bi-x fs-2"></i>
                                                        </span>



                                                    </div>


                                                    <div className="form-text">Allowed file types: png, jpg, jpeg.</div>

                                                </div>


                                            ) : null


                                        }

                                    </div>

                                    {/*FLAYER DE INSCRIPCION*/}

                                    <div className="fv-row mb-4 text-center ">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="flayer">Flayer de Inscripción </label>

                                        {
                                            (!eventFlayerInscripciones) ? (
                                                <div className="col-lg-12">

                                                    <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: "url(" + defaultFlayerInscripciones + ")" }}>

                                                        <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: "url(" + defaultFlayerInscripciones + ")" }}></div>

                                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" title="Change avatar">
                                                            <i className="bi bi-pencil-fill fs-7"></i>

                                                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={imageFlayerInscripcionesChange} />


                                                        </label>

                                                    </div>

                                                    <div className="form-text">Allowed file types: png, jpg, jpeg.</div>

                                                </div>


                                            ) : null
                                        }
                                        {
                                            (eventFlayerInscripciones) ? (
                                                <div className="col-lg-12">

                                                    <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: "url(" + defaultFlayerInscripciones + ")" }}>

                                                        <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: "url(" + defaultFlayerInscripciones + ")" }}></div>

                                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" title="Change avatar">
                                                            <i className="bi bi-pencil-fill fs-7"></i>

                                                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={imageFlayerInscripcionesChange} />


                                                        </label>

                                                        <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"

                                                            data-kt-image-input-action="remove"
                                                            title="Remove avatar"
                                                            onClick={() => setDefaultImageFlayerInscription()}>

                                                            <i className="bi bi-x fs-2"></i>
                                                        </span>



                                                    </div>


                                                    <div className="form-text">Allowed file types: png, jpg, jpeg.</div>

                                                </div>

                                            ) : null


                                        }
                                        {/*CHANGE*/}

                                    </div>

                                    {/*FLAYER DE RESULTADOS*/}
                                    <div className="fv-row mb-4 text-center ">

                                        <label className="form-label fs-6 fw-bolder text-dark" htmlFor="flayer">Flayer de Resultados </label>

                                        {
                                            (!eventFlayerResultados) ? (
                                                <div className="col-lg-12">

                                                    <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: "url(" + defaultFlayerResultados + ")" }}>

                                                        <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: "url(" + defaultFlayerResultados + ")" }}></div>

                                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" title="Change avatar">
                                                            <i className="bi bi-pencil-fill fs-7"></i>

                                                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={imageFlayerResultadosChange} />


                                                        </label>

                                                    </div>

                                                    <div className="form-text">Allowed file types: png, jpg, jpeg.</div>

                                                </div>


                                            ) : null
                                        }
                                        {
                                            (eventFlayerResultados) ? (
                                                <div className="col-lg-12">

                                                    <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: "url(" + defaultFlayerResultados + ")" }}>

                                                        <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: "url(" + defaultFlayerResultados + ")" }}></div>

                                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" title="Change avatar">
                                                            <i className="bi bi-pencil-fill fs-7"></i>

                                                            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={imageFlayerResultadosChange} />


                                                        </label>

                                                        <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"

                                                            data-kt-image-input-action="remove"
                                                            title="Remove avatar"
                                                            onClick={() => setDefaultImageFlayerResultados()}>

                                                            <i className="bi bi-x fs-2"></i>
                                                        </span>



                                                    </div>


                                                    <div className="form-text">Allowed file types: png, jpg, jpeg.</div>

                                                </div>

                                            ) : null


                                        }


                                    </div>

                                </div>
                            </form>

                        </div>

                        {enableModalCategory ? (<ModalAddLine addNewLineCategory={addNewLineCategory} setEnableModalCategory={setEnableModalCategory} />) : null}

                    </div>

                </div>

            </div>

        </div>
    )
}
