import Login from "../components/Login";
import React from "react";
import {
    Route,
    Routes
} from "react-router-dom";


import PersisLogin from "../components/PersisLogin";
import Logout from "../components/Logout";

import Eventos from "../components/eventos";
import BakgroundSliderMain from "../components/BakgroundSliderMain";

import Competidores from "../components/Competidores";
import Publicidad from "../components/Publicidad";
import Sponsors from "../components/Sponsors";



export default function RouterPaths() {

    return (

        <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path={"/logout"} element={<Logout />}></Route>
            <Route element={<PersisLogin  />}>
                <Route path="/eventos" element={<Eventos />}></Route>
                <Route path="/bground-slider-main" element={<BakgroundSliderMain />}></Route>
                <Route path="/competidores" element={<Competidores />}></Route>
                <Route path="/publicidad" element={<Publicidad />}></Route>
                <Route path="/sponsors" element={<Sponsors />}></Route>

            </Route>


        </Routes>


    );

}
