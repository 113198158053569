import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
// import { useNavigate} from "react-router-dom";

//styles

import { serverHost } from '../services/utils'
import { handlerErrorResponse, postLogin, validateFields } from "./functions/process";
import useStyles from "./styles/styles";

const Login = () => {

    const classes = useStyles();
    const { setAuth, persist } = useAuth();
    // const navigate = useNavigate()

    const [errorGlobal, setErrorGlobal] = useState([])
    const [loading, setLoading] = useState(false)

    const [errors, setErrors] = useState({
        errorsResponse: "",
        errorUser: "",
        errorPassword: ""
    })
    useEffect(() => {
        setErrors(errorGlobal)

    }, [errorGlobal])


    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault();

        let uservalidat = validateFields(user, "email", true, "Email")
        setErrorGlobal({ ...errors, errorUser: uservalidat.mensg })

        let passwordvalidat = validateFields(password, "password", true, "Password")
        setErrorGlobal({ ...errors, errorPassword: passwordvalidat.mensg })

        setLoading(true)
        if (uservalidat.success && passwordvalidat.success) {

            postLogin({ email: user, password: password }).then((resp) => {
      
                let token = resp.token
                let name = resp.user.name
                setAuth({user,password,token,name});
                
                localStorage.setItem('token',token)

                setErrorGlobal({ ...errors, errorsResponse: "" })
                setLoading(false)

       
                window.location.href="./eventos"
                
            }).catch((err) => {
                console.log('err',err)
              
                let resp = handlerErrorResponse(err)
                setErrorGlobal({ ...errors, errorsResponse: resp })
                setLoading(false)
            })

        } else {
            setLoading(false)

        }

    }
    useEffect(() => {
        localStorage.setItem("persist", JSON.stringify(persist));

    }, [persist])

    return (
        <div className="d-flex flex-column flex-root">

            <div className="d-flex flex-column flex-lg-row flex-column-fluid">

                <div className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative" >

                    <div className={"d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y "} style={{ backgroundImage: "url(" + serverHost + "/assets/images/admin/portada.jpg)", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} >

                        <div className={"d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20 " + classes.overlayV1}>

                            <button type="button" className="py-9 mb-5 overlay ">
                                <img className={classes.Img80} alt="Logo" src={`${serverHost}/assets/images/logo-v1.png`} />
                            </button>
                        </div>



                    </div>
                </div>

                <div className="d-flex flex-column flex-lg-row-fluid py-10 pt-lg-20">

                    <div className="d-flex flex-center flex-column flex-column-fluid">

                        <div className="w-lg-500px p-10 p-lg-15 mx-auto">

                            <form className="form w-100 " id="kt_sign_in_form" action="#" onSubmit={handleSubmit} noValidate>

                                <div className="text-center mb-10">

                                    <h1 className="text-dark mb-3">FULLRUNNERS.NET</h1>

                                    <div className="text-gray-400 fw-bold fs-4">Bienvenido a tu asistente de administración

                                    </div>

                                </div>

                                {
                                    (errors.errorsResponse) ? (<>
                                        <div className="alert alert-danger" role="alert">
                                            {errors.errorsResponse}
                                        </div>
                                    </>
                                    ) : null
                                }

                                <div className="fv-row mb-10">

                                    <label className="form-label fs-6 fw-bolder text-dark" htmlFor="email">Email</label>

                                    <input className={"form-control form-control-lg form-control-solid" + classes.bgInput + ((errors.errorUser) ? " is-invalid" : "")}
                                        id="email"
                                        type="text"
                                        name="email"
                                        autoComplete="off"
                                        placeholder='Email'
                                        onChange={(e) => setUser(e.target.value)}
                                        value={user}
                                        required
                                    />


                                    <div className={'invalid-feedback' + ((errors.errorUser) ? " d-block" : null)}>{errors.errorUser}</div>


                                </div>

                                <div className="fv-row mb-10">
                                    <div className="d-flex flex-stack mb-2">

                                        <label className="form-label fw-bolder text-dark fs-6 mb-0">Contraseña</label>

                                    </div>

                                    <input className={"form-control form-control-lg form-control-solid" + classes.bgInput + ((errors.errorPassword) ? " is-invalid" : "")}
                                        type="password" name="password"
                                        autoComplete="off"
                                        placeholder='Contraseña'
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        required
                                    />
                                    <div className={'invalid-feedback' + ((errors.errorPassword) ? " d-block" : null)}>{errors.errorPassword}</div>


                                </div>

                                <div className="text-center">
                                    <button id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">


                                        {
                                            (!loading) ? <>
                                                <span className="indicator-label" >Acceder</span>
                                            </> : null
                                        }
                                        {
                                            (loading) ? <>

                                                <span className={"indicator-progress d-block"}>Accediendo...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </> : null
                                        }

                                    </button>



                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )


}
export default Login;
