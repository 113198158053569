import React, { useState } from 'react';

import style from './select.module.css'

const Select2Multiple = ({ options, onChangeFilter }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    if (!selectedOptions.includes(option)) {
      setSelectedOptions([...selectedOptions, option]);
    }
    
    let forUpdate = [...selectedOptions, option]
    onChangeFilter(forUpdate)
    setIsOpen(false);
  };

  const handleRemove = (option) => {
    const resultados = selectedOptions.filter(item => item !== option);
    setSelectedOptions(resultados);

    let forUpdate = resultados
    onChangeFilter(forUpdate)
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  const filteredOptions = options

  return (
    <div className={style.select2Multiple}>
      <div className={style.select2Multiple__control} onClick={toggleDropdown}>
        <div className={style.select2Multiple__value}>
          {selectedOptions.map(option => (
            <span key={option} className={style.select2Multiple__tag}>
              {option}
              <span className={style.select2Multiple__remove} onClick={(e) => {
                e.stopPropagation();
                handleRemove(option);
              }}>x</span>
            </span>
          ))}
      
        </div>
        <span className={style.select2Multiple__arrow}>{isOpen ? '▲' : '▼'}</span>
      </div>
      {isOpen && (
        <div className={style.select2Multiple__menu}>
          {filteredOptions.map(option => (
            <div
              key={option}
              className={`${style.select2Multiple__option} ${selectedOptions.includes(option) ? style.selected : ''}`}
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select2Multiple;
