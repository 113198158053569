import React, { useEffect, useState } from 'react'
import Pagination from '../Pagination'

import Footer from '../Footer'
import Nav from '../Nav'

import ScrollTop from '../ScrollTop'
import { getEventBackgroundImage } from '../functions/process';
import ModalCreate from './ModalCreate';
import ModalEventEdit from './ModalEdit';

export default function BakgroundSliderMain() {

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isOpenModalEdit, setIsOpenModalEdit] = useState(false)

 
    const [eventsLists, setEventsLists] = useState([])

    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [enableSearch, setEnableSearch] = useState(true)
    const [idSelected, setIdSelected] = useState(0)


    useEffect(() => {

        if (enableSearch) {
            ///GET DATA
           
            getEventBackgroundImage(currentPage).then((resp) => {

              //  console.log('resp',resp)
                if (resp.data.length) {
                    setEventsLists(resp.data)
                    setLastPage(resp.last_page)
                }
            })
            setEnableSearch(false)
        }
    }, [currentPage, enableSearch])
    const handleChange = (e, page) => {

        setCurrentPage(page)
        setEnableSearch(true)
    }
    const renderEventsList = (item) => {

        return (

            <tr key={item.id}>
                <td className="min-w-120px">
                    <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px" onClick={() => openModalEdit(item)}>
                        <span className="svg-icon svg-icon-5 svg-icon-gray-700">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z" fill="currentColor"></path>
                                <path opacity="0.3" d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z" fill="currentColor"></path>
                            </svg>
                        </span>
                    </button>
                </td>
                <td className="min-w-120px">{item.title}</td>
                <td className="min-w-120px">{item.subtitle}</td>
                <td className="min-w-120px">{item.created_at}</td>

            </tr>
        )
    }

    const openModal = () => {

        setIsOpenModal(true)
    }
    const openModalEdit = (item) => {


        setIdSelected(item.id)
        setIsOpenModalEdit(true)
    }

    return (

        <div id="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed" >

            <div className="d-flex flex-column flex-root">

                <div className="page d-flex flex-row flex-column-fluid">

                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <Nav active={'bground-slider-main'} />

                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="toolbar" id="kt_toolbar">

                                <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack  mb-4">

                                    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">

                                        <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Seccion de Imagenes

                                            <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        </h1>

                                    </div>


                                </div>

                            </div>

                            <div className="post d-flex flex-column-fluid" id="kt_post">

                                <div id="kt_content_container" className="container-xxl">

                                    <div className="row gy-5 g-xl-8">



                                        <div className="col-xl-11">

                                            <div className="card card-xl-stretch mb-5 mb-xl-8">

                                                <div className="card-header border-0 pt-5">
                                                    <h3 className="card-title align-items-start flex-column">

                                                        <span className="text-muted mt-1 fw-bold fs-7">LISTADO DE IMAGEN EN BACKGROUND DE INICIO</span>
                                                    </h3>
                                                    <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to add">

                                                        <span href="#" className="btn btn-sm btn-light btn-active-primary"
                                                            onClick={() => openModal()}>

                                                            <span className="svg-icon svg-icon-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black" />
                                                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black" />
                                                                </svg>
                                                            </span>
                                                            Subir Nueva
                                                        </span>
                                                    </div>
                                                </div>


                                                <div className="card-body py-3">

                                                    <div className="table-responsive">

                                                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">

                                                            <thead>
                                                                <tr className="fw-bolder text-muted">
                                                                    <th className="min-w-120px"></th>

                                                                    <th className="min-w-120px">Titulo</th>
                                                                    <th className="min-w-120px">Subtitulo</th>
                                                                    <th className="min-w-120px">Creado</th>
                                                                </tr>
                                                            </thead>


                                                            <tbody>
                                                                {
                                                                    (eventsLists.length > 0) ?
                                                                        eventsLists.map(renderEventsList)
                                                                        :
                                                                        (<>
                                                                            <tr className='text-center'>
                                                                                <td colSpan={6}>

                                                                                    <p className='text-muted'> No data available.</p>
                                                                                </td>
                                                                            </tr>

                                                                        </>)

                                                                }

                                                            </tbody>

                                                        </table>
                                                        <div className='d-flex justify-content-center'>
                                                            <Pagination
                                                                count={lastPage}
                                                                size="small"
                                                                page={currentPage}
                                                                onChange={handleChange}
                                                                variant='outlined'
                                                            />

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>
            </div>
            <ModalCreate isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} setEnableSearch={setEnableSearch} />
            <ModalEventEdit id={idSelected} isOpenModal={isOpenModalEdit} setIsOpenModal={setIsOpenModalEdit} setEnableSearchMain={setEnableSearch} />

            <ScrollTop />
        </div>

    )

}