import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({

    overlayV1: {
        backgroundColor: "rgba(255, 255, 255, 0.4)"
    },
    Img80: {
        width: "80% !important"
    },
    bgInput: {
        backgroundColor: "#EEF1F4",
        borderColor: "#EEF1F4"
    }

});

export default useStyles;