import { configureStore } from '@reduxjs/toolkit';
// reducer
import eventos from './reducers/eventos';

const hooks = configureStore({
  reducer: {
    eventos
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  })
});

export default hooks